import {createRouter, createWebHistory} from "vue-router";
import {isMobile} from "@/utils/libs";

const routes = [
    {
        name: "home",
        path: "/",
        redirect: "/chat",
        meta: {title: "首页"},
        component: () => import("@/views/Home.vue"),
        children: [
            {
                name: "chat",
                path: "/chat",
                meta: {title: "对话"},
                component: () => import("@/views/ChatPlus.vue")
            },
            {
                name: "draw",
                path: "/draw",
                meta: {title: "绘画"},
                component: () => import("@/views/ImageMj.vue")
            },
            {
                name: "video",
                path: "/video",
                meta: {title: "视频"},
                component: () => import("@/views/Pika.vue")
            },
            {
                name: "image-sd",
                path: "/sd/",
                meta: {title: "Stable Diffusion 绘画中心"},
                component: () => import("@/views/ImageSd.vue")
            },
            {
                name: "member",
                path: "/member",
                meta: {title: "充值"},
                component: () => import("@/views/Member.vue")
            },
            {
                name: "chat-role",
                path: "/apps",
                meta: {title: "应用"},
                component: () => import("@/views/ChatApps.vue")
            },
            {
                name: "galleries",
                path: "/galleries",
                meta: {title: "作品"},
                component: () => import("@/views/ImagesWall.vue")
            },
            {
                name: "lessons",
                path: "/lessons",
                meta: {title: "课程"},
                component: () => import("@/views/LessonsWall.vue")
            },
            {
                name: "courseList",
                path: "/courseList",
                meta: {title: "课程"},
                component: () => import("@/views/CourseList.vue")
            },
            {
                name: "user-invitation",
                path: "/invite",
                meta: {title: "推广"},
                component: () => import("@/views/Invitation.vue")
            },
            {
                name: "knowledge",
                path: "/knowledge",
                meta: {title: "知识库"},
                component: () => import("@/views/Knowledge.vue")
            },
            {
                name: "industry-model",
                path: "/industryModel",
                meta: {title: "轻模型"},
                component: () => import("@/views/IndustryModel.vue")
            },

            {
                name: "user",
                path: "/user",
                meta: {title: "个人中心"},
                component: () => import("@/views/User.vue")
            },
            {
                name: "points",
                path: "/points",
                meta: {title: "积分详情"},
                component: () => import("@/views/Points.vue")
            },
            {
                name: "chatqma",
                path: "/chatqma",
                meta: {title: "一问多答"},
                component: () => import("@/views/Chatqma.vue")
            },
            {
                name: "music",
                path: "/music",
                meta: {title: "音乐"},
                component: () => import("@/views/Music.vue")
            }
        ]
    },
    {
        name: "chat-export",
        path: "/chat/export",
        meta: {title: "导出会话记录"},
        component: () => import("@/views/ChatExport.vue")
    },
    {
        name: "login",
        path: "/login",
        meta: {title: "用户登录"},
        component: () => import("@/views/Login.vue")
    },
    {
        name: "register",
        path: "/register",
        meta: {title: "用户注册"},
        component: () => import("@/views/Register.vue")
    },
    {
        path: "/pcadmin/login",
        name: "admin-login",
        meta: {title: "控制台"},
        component: () => import("@/views/admin/Login.vue")
    },
    {
        name: "admin",
        path: "/pcadmin",
        redirect: "/pcadmin/dashboard",
        component: () => import("@/views/admin/Home.vue"),
        meta: {title: "六合AI融合模型 管理后台"},
        children: [
            {
                path: "/pcadmin/dashboard",
                name: "admin-dashboard",
                meta: {title: "工作台"},
                component: () => import("@/views/admin/Dashboard.vue")
            },
            {
                path: "/pcadmin/consumer/lists",
                name: "admin-consumer-lists",
                meta: {title: "用户列表"},
                component: () => import("@/views/admin/Consumer.vue")
            },
            {
                path: "/pcadmin/consumer/grouping",
                name: "admin-consumer-grouping",
                meta: {title: "用户分组"},
                component: () => import("@/views/admin/ConsumerGroup.vue")
            },
            {
                path: "/pcadmin/site/mj",
                name: "site-mj",
                meta: {title: "绘画配置"},
                component: () => import("@/views/admin/site/MjConfig.vue")
            },
            {
                path: "/pcadmin/role",
                name: "admin-role",
                meta: {title: "应用管理"},
                component: () => import("@/views/admin/Roles.vue")
            },
            {
                path: "/pcadmin/chat/key",
                name: "admin-chat-key",
                meta: {title: "API-KEY 管理"},
                component: () => import("@/views/admin/ChatKey.vue")
            },
            {
                path: "/pcadmin/chat/model",
                name: "admin-chat-model",
                meta: {title: "语言模型"},
                component: () => import("@/views/admin/ChatModel.vue")
            },
            {
                path: "/pcadmin/product/list",
                name: "admin-product-list",
                meta: {title: "充值产品"},
                component: () => import("@/views/admin/material/Product.vue")
            },
            {
                path: "/pcadmin/finance/order/list",
                name: "admin-finance-order-list",
                meta: {title: "订单记录"},
                component: () => import("@/views/admin/finance/OrderList.vue")
            },
            {
                path: "/pcadmin/finance/consume/list",
                name: "admin-finance-consume-list",
                meta: {title: "消费记录"},
                component: () => import("@/views/admin/finance/ConsumeList.vue")
            },
            {
                path: "/pcadmin/chats",
                name: "admin-chats",
                meta: {title: "对话管理"},
                component: () => import("@/views/admin/ChatList.vue")
            },
            {
                path: "/pcadmin/draw",
                name: "admin-draw",
                meta: {title: "绘画管理"},
                component: () => import("@/views/admin/DrawList.vue")
            },
            {
                path: "/pcadmin/music",
                name: "admin-music",
                meta: {title: "音乐管理"},
                component: () => import("@/views/admin/MusicList.vue")
            },
            {
                path: "/pcadmin/music/key",
                name: "admin-music-key",
                meta: {title: "音乐key管理"},
                component: () => import("@/views/admin/MusicKey.vue")
            },
            {
                path: "/pcadmin/loginLog",
                name: "admin-loginLog",
                meta: {title: "登录日志"},
                component: () => import("@/views/admin/LoginLog.vue")
            },
            {
                path: "/pcadmin/demo/form",
                name: "admin-form",
                meta: {title: "表单页面"},
                component: () => import("@/views/admin/demo/Form.vue")
            },
            {
                path: "/pcadmin/demo/table",
                name: "admin-table",
                meta: {title: "数据列表"},
                component: () => import("@/views/admin/demo/Table.vue")
            },
            {
                path: "/pcadmin/demo/import",
                name: "admin-import",
                meta: {title: "导入数据"},
                component: () => import("@/views/admin/demo/Import.vue")
            },
            {
                path: "/pcadmin/demo/editor",
                name: "admin-editor",
                meta: {title: "富文本编辑器"},
                component: () => import("@/views/admin/demo/Editor.vue")
            },
            {
                path: '/pcadmin/system/user',
                name: 'admin-system-user',
                meta: {title: '用户管理'},
                component: () => import('@/views/admin/SystemUser.vue'),
            },
            {
                path: '/pcadmin/system/mewu',
                name: 'admin-system-mewu',
                meta: {title: '菜单管理'},
                component: () => import('@/views/admin/SystemMenu.vue'),
            },
            {
                path: '/pcadmin/redeemCode/list',
                name: 'admin-redeemCode-list',
                meta: {title: '卡密列表'},
                component: () => import('@/views/admin/material/RedeemCodeList.vue'),
            },
            {
                path: '/pcadmin/invite/conf',
                name: 'admin-invite-conf',
                meta: {title: '邀请设置'},
                component: () => import('@/views/admin/material/InviteConf.vue'),
            },
            {
                path: '/pcadmin/invite/list',
                name: 'admin-invite-list',
                meta: {title: '邀请列表'},
                component: () => import('@/views/admin/material/InviteList.vue'),
            },
            {
                path: "/pcadmin/setting/config",
                name: "setting-config",
                meta: {title: "全局配置"},
                component: () => import("@/views/admin/setting/Config.vue")
            },
            {
                path: "/pcadmin/setting/notice",
                name: "setting-notice",
                meta: {title: "公告设置"},
                component: () => import("@/views/admin/setting/Notice.vue")
            }
        ]
    },
    {
        name: "mobile",
        path: "/mobile",
        meta: {title: "六合AI融合模型"},
        component: () => import("@/views/mobile/Home.vue"),
        redirect: "/mobile/chat",
        children: [
            {
                path: "/mobile/chat",
                name: "mobile-chat",
                component: () => import("@/views/mobile/ChatSession.vue")
            },
            {
                path: "/mobile/setting",
                name: "mobile-setting",
                meta: {title: "设置"},
                component: () => import("@/views/mobile/Setting.vue")
            },
            {
                path: "/mobile/draw",
                name: "mobile-draw",
                meta: {title: "绘画"},
                component: () => import("@/views/mobile/Mj.vue")
            },
            {
                path: "/mobile/video",
                name: "mobile-video",
                meta: {title: "视频"},
                component: () => import("@/views/mobile/Pika.vue")
            },
            {
                path: "/mobile/chatQma",
                name: "mobile-chat-qma",
                meta: {title: "一问多答"},
                component: () => import("@/views/mobile/ChatQma.vue")
            },
            {
                path: "/mobile/apps",
                name: "mobile-apps",
                meta: {title: "应用中心"},
                component: () => import("@/views/mobile/Applications.vue")
            },
            {
                path: "/mobile/galleries",
                name: "mobile-galleries",
                meta: {title: "作品墙"},
                component: () => import("@/views/mobile/Galleries.vue")
            },
            {
                path: "/mobile/knowledge",
                name: "mobile-knowledge",
                meta: {title: "知识库"},
                component: () => import("@/views/mobile/Knowledge.vue")
            },
            {
                path: "/mobile/lessons",
                name: "mobile-lesson",
                meta: {title: "课程"},
                component: () => import("@/views/mobile/Lessons.vue")
            },
            {
                name: "mobile-courseList",
                path: "/mobile/courseList",
                meta: {title: "课程"},
                component: () => import("@/views/CourseList.vue")
            },
            {
                path: "/mobile/member",
                name: "mobile-member",
                meta: {title: "会员计划"},
                component: () => import("@/views/mobile/Member.vue")
            },
            {
                path: "/mobile/invitation",
                name: "mobile-invitation",
                meta: {title: "推广计划"},
                component: () => import("@/views/mobile/Invitation.vue")
            },
            {
                path: "/mobile/user",
                name: "mobile-user",
                meta: {title: "个人中心"},
                component: () => import("@/views/mobile/User.vue")
            },
            {
                path: "/mobile/profile",
                name: "mobile-profile",
                component: () => import("@/views/mobile/user/Profile.vue")
            },
            {
                path: "/mobile/modifyPassword",
                name: "mobile-modify-password",
                component: () => import("@/views/mobile/user/ModifyPassword.vue")
            },
            {
                path: "/mobile/bindPhone",
                name: "mobile-bind-phone",
                component: () => import("@/views/mobile/user/BindPhone.vue")
            },
            {
                path: "/mobile/exchangeCredits",
                name: "mobile-exchange-credits",
                component: () => import("@/views/mobile/user/ExchangeCredits.vue")
            },
            {
                path: "/mobile/contactUs",
                name: "mobile-contact-us",
                component: () => import("@/views/mobile/user/ContactUs.vue")
            },
            {
                path: "/mobile/scoreDetail",
                name: "mobile-score-detail",
                component: () => import("@/views/mobile/user/ScoreDetail.vue")
            },
            {
                path: "/mobile/industryModel",
                name: "mobile-industry-model",
                component: () => import("@/views/mobile/IndustryModel.vue")
            },
            {
                path: "/mobile/music",
                name: "mobile-music",
                component: () => import("@/views/mobile/Music.vue")
            }
        ]
    },
    {
        path: "/share",
        name: "mobile-share",
        component: () => import("@/views/mobile/Share.vue")
    },
    {
        path: "/mobile/login",
        name: "mobile-login",
        component: () => import("@/views/mobile/Login.vue")
    },

    {
        path: "/user-protocol",
        name: "user-protocol",
        component: () => import("@/components/agreement/UserProtocol.vue")
    },
    {
        path: "/private-policy",
        name: "private-policy",
        component: () => import("@/components/agreement/PrivatePolicy.vue")
    },
    {
        name: "NotFound",
        path: "/:all(.*)",
        meta: {title: "页面没有找到"},
        component: () => import("@/views/404.vue")
    }
];

// console.log(MY_VARIABLE)
const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

// dynamic change the title when router change
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
    }
    if (from.path === "/" && to.path === "/chat" && isMobile()) {
        next("/mobile");
    } else {
        next();
    }
});
router.onError((error) => {
    if (router.history) {
        const pattern = /Loading chunk (\d)+ failed/g;
        const isChunkLoadFailed = error.message.match(pattern);
        const targetPath = router.history.pending.fullPath;
        if (isChunkLoadFailed) {
            router.replace(targetPath);
        }
    }
});
export default router;
